<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Advanced Image Processing</h1>
      <div class="btns-container">
        <div class="md-layout-item vasion-flex-end">
          <div>
            <md-menu
              md-align-trigger
              md-size="big"
              md-direction="bottom-end"
              :mdCloseOnSelect="true"
              :mdCloseOnClick="true"
            >
              <md-button
                id="btn-new-configuration-type"
                md-menu-trigger
                class="v-btn-primary"
                :disabled="false"
              >
                New Configuration Type
                <VasionArrowDropDownIcon />
              </md-button>
              <md-menu-content class="dropdown-list-container">
                <md-menu-item @click="newAip('Vasion AIP')">
                  Vasion AIP
                </md-menu-item>
                <md-menu-item v-if="showTextract" @click="newAip('Amazon Textract')">
                  Amazon Textract
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="vap-page-content">
      <div v-if="whiteScreen === true">
        <!-- Show white space while waiting for data to load -->
      </div>
      <div v-else-if="isDataAvailable === true && tableData.Columns" class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
      <div v-else class="no-items-div">
        <div class="vasion-page-subheader instructional-message-div">
          No data to show.
        </div>
        <div class="button-div">
          <VasionButton :isFullWidth="true" :classProp="'primary'" @vasionButtonClicked="createNewClicked()">
            <span class="vasion-button-plus"> + </span> ADD NEW
          </VasionButton>
        </div>
      </div>
    </div>
    <VasionGeneralModal
      id="aip-delete-modal"
      :modalType="'message'"
      buttonGroup="center"
      confirmButtonText="Yes"
      rejectButtonText="No"
      :message="deleteMessage"
      :sync="showDeleteModal"
      @noButtonClick="toggleDeleteModal"
      @confirmButtonClick="deleteAip"
    />

    <VasionGeneralModal
      id="textract-not-allowed"
      :modalType="'slot'"
      buttonGroup="center"
      confirmButtonText="Close"
      modalTitle="Warning"
      :showRejectButton=false
      :sync="showTextractModal"
      @confirmButtonClick="closeTextract"
    >
      You are not licensed to use Amazon Textract.  Please contact support@vasion.com or go to <a href="https://vasion.com/aws-textract">Vasion Amazon Textract</a> for more information.
    </VasionGeneralModal>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'AdvancedImageProcessing',
  components: {
    Loading,
  },
  data: function () {
    return {
      aipID: 0,
      aipName: '',
      columns: {
        Values: [
          '_AipID_',
          'Name',
          'Configuration Type',
          'Object',
          '_VasionEllipsisButton_',
        ],
        ValueType: 'String',
      },
      deleteMessage: '',
      hiddenColumns: ['_AipID_'],
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      showDeleteModal: false,
      showTextractModal: false,
      showSnackbar: false,
      showTextract: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: {},
      type: 'Vasion AIP',
      whiteScreen: true,
    }
  },
  computed: {
    aipData() { return this.$store.state.capture.aipData },
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    isDataAvailable() { return this.aipData.Values && this.aipData.Values.length > 0 },
  },
  async created() {
    await this.$store.dispatch('security/setEllipsisButtonConfig', 'aip-buttons')
    await this.refreshTable()
    this.showTextract = this.$store.state.systemSettings.featureFlags.aipTextractEnable
  },
  methods: {
    async closeTextract() {
      this.showTextractModal = false
    },
    createNewClicked() { this.$router.push({ name: 'EditAIP', params: { AIPID: 0 } }) },
    async deleteAip() {
      let deleteAipEndpoint = 'capture/deleteAip'
      if (this.type === 'Amazon Textract')
        deleteAipEndpoint = 'admin/deleteTextractAIP'
      
      const response = await this.$store.dispatch(deleteAipEndpoint, this.aipID)
      this.showDeleteModal = false

      if (this.type === 'Vasion AIP') {
        this.snackbarTitle = !response ? 'Error Deleting Advanced Image Process' : `Advanced Image Process: "${this.aipName}" Deleted Successfully`
        this.snackbarSubTitle = !response ? 'If the problem persists, please contact your Administrator' : 'The Advanced Image Process has been deleted.'
        this.snackbarImage = response
        this.showSnackbar = true;
      }

      if (this.type === 'Amazon Textract') {
        this.snackbarTitle = response?.Value === "True" ? `Advanced Image Process: "${this.aipName}" Deleted Successfully` : 'Error Deleting Advanced Image Process'
        this.snackbarSubTitle = response?.Value === "True" ? 'The Advanced Image Process has been deleted.' : 'If the problem persists, please contact your Administrator'
        this.snackbarImage = response.Value === "True"
        this.showSnackbar = true;
      }

      this.refreshTable()
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        [this.aipID, this.aipName] = [payload.item.Values[0], payload.item.Values[1]]
        const type = payload.item.Values[2]

        switch (payload.buttonId) {
          case 'edit-aip-button':
            if (type === 'Vasion AIP')
              this.$router.push({ name: 'EditAIP', params: { AIPID: this.aipID } })
            if (type === 'Amazon Textract' && this.showTextract)
              this.$router.push({ name: 'EditTextract', params: { AIPID: this.aipID } })
            break;
          case 'delete-aip-button':
            this.type = type
            this.deleteMessage = `Are you sure you want to delete "${this.aipName}"?`
            this.toggleDeleteModal()
            break;
          default:
            break;
        }
      }
    },
    async newAip(type) {
      if (type === 'Vasion AIP')
        this.$router.push({ name: 'EditAIP', params: { AIPID: 0 } })
      if (type === 'Amazon Textract' && this.showTextract) {

        if(!await this.$store.dispatch('common/getIsTextractLicensed')) {
          this.showTextractModal = true
          return
        }
        this.$router.push({ name: 'NewTextract'})
      }
    },
    async refreshTable() {
      this.isLoading = true
      await this.$store.dispatch('capture/getAllAipProcs')
      this.whiteScreen = false
      const data = {
        aipList: this.aipData.Values,
        columns: this.columns,
        type: 'Aip',
      }
      this.tableData = await this.$store.dispatch('capture/setTableData', data)
      this.isLoading = false
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      const type = data.Values[2]
      if (type === 'Vasion AIP')
        this.$router.push({ name: 'EditAIP', params: { AIPID: data.Values[0] } })
      if (type === 'Amazon Textract' && this.showTextract)
        this.$router.push({ name: 'EditTextract', params: { AIPID: data.Values[0] } })
    },
    toggleDeleteModal() { this.showDeleteModal = !this.showDeleteModal },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';
@import '@/assets/css/btn-styles.scss';

.button-div {
  margin: auto;
  margin-top: 30px;
  width: 288px;
}

.inner-div {
  padding-top: 8px;
  margin-right: 8px;
  margin-left: 16px;
  text-align: center;
}

.instructional-message-div {
  margin-top: 24px;
}

.no-items-div {
  margin-top: 60px;
}

.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
  text-align: left;
}

.mb-8px {
  margin-bottom: 8px;
}

.dropdown-list-container {
  margin-top: 4px;
}

.dropdown-list-container,
.dropdown-list-container ::v-deep .md-menu-content-container {
  border-radius: 8px;
}

.dropdown-list-container ::v-deep .md-list {
  padding: 0px;
}

.dropdown-list-container ::v-deep button.md-list-item-button:hover {
  background-color: $primary-container !important;
}
</style>
